<template>
  <div class="layout-content-renderer-left">
    <!-- {{ isVerticalMenuActive }} / {{ overlayClasses }} -->
    <div class="renderer-left-container">
      <div
        class="renderer-left-sidebar"
        :class="overlayClasses"
      >
        <app-sidebar />
        <!-- {{ toggleVerticalMenuActive }} -->
      </div>

      <div class="renderer-left-content">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>

        <!-- <button
          class="content-toggle-button"
          @click="toggleVerticalMenuActive"
        >
          {{ isVerticalMenuActive ? '隱藏側邊欄' : '顯示側邊欄' }}
        </button> -->
      </div>

      <!-- <div class="renderer-left-sidebar-toggle">
        <div
          class="content-toggle-button"
          @click="toggleVerticalMenuActive"
        >
          <div class="hamburger-lines">
            <span class="line line1" />
            <span class="line line2" />
            <span class="line line3" />
          </div>
        </div>
      </div> -->
    </div>

    <Footer />

    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
// import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
// import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
// import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
// import VerticalNavMenu from './components/VerticalNavMenu.vue'
import { $themeBreakpoints } from '@themeConfig'
import { useVerticalLayout } from '../useVerticalLayout'
import AppSidebar from '../components/app-sidebar/AppSidebar.vue'
// import mixinVerticalLayout from '../mixinVerticalLayout'
// import Footer from '@/components/Footer/Footer.vue'

export default {
  components: {
    AppSidebar,
    // Footer,
    // VuePerfectScrollbar,
  },
  // mixins: [mixinVerticalLayout],
  watch: {
    $route() {
      if (this.$store.state.app.windowWidth < $themeBreakpoints.xl) {
        this.isVerticalMenuActive = false
      }
    },
  },
  setup() {
    const {
      routerTransition, // navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      resizeHandler,
    } = useVerticalLayout()

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      // layoutClasses,
      // navbarTypeClass,
      // footerTypeClass,

      // App Config
      routerTransition,
      // navbarBackgroundColor,
      // isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '@/assets/scss/variables/_variables.scss';
$navbar-height: 80px;

.layout-content-renderer-left {
  position: relative;

  .renderer-left-container {
    display: flex;
    // position: relative;
    // background-color: teal;

    .renderer-left-sidebar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      // min-height: 100vh;
      // height: 100vh;
      width: 300px;
      // padding: 0 10px;
      transition: transform 0.3s ease, width 0.3s ease, opacity 0.3s ease;
      // z-index: 998;
      margin-top: $navbar-height;
      // background-color: red;
    }

    .renderer-left-content {
      flex-grow: 1;
      z-index: 10;
      transition: margin-left 0.3s ease, transform 0.3s ease;
      margin-left: 0;
      min-height: 100vh;
      // overflow-y: scroll;
      // max-height: 100vh;
      // padding: calc(2rem + 4.45rem) 2rem 0 2rem;
      padding: calc(2rem + #{$navbar-height}) 2rem 2rem 2rem; // 4rem
      // background: yellow;

      @include media-breakpoint-up(xl) {
        margin-left: 300px;
      }
    }

    .renderer-left-sidebar-toggle {
      position: fixed;
      // display: none;
      // top: 0;
      height: 100%;
      left: 0;
      // width: 5px;
      // background-color: #82cfee;
      cursor: pointer;
      z-index: 100;
      .content-toggle-button {
        position: absolute;
        // display: none;
        top: $navbar-height + 20px;
        height: 40px;
        width: 40px;
        background-color: #82cfee;
        border-radius: 0 15px 15px 0;

        .hamburger-lines {
          display: block;
          height: 20px;
          width: 24px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          z-index: 2;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .line {
            display: block;
            height: 4px;
            width: 100%;
            border-radius: 10px;
            background: #f8f8f8;
          }
        }

        &:hover {
          background-color: #1db9ca;
        }
      }
    }
  }

  // .my-sidenav-overlay {
  //   // display: none;
  //   // position: absolute;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 997;
  //   height: 100vh;
  //   transition: opacity 0.3s ease;

  //   &.show {
  //     // display: flex;
  //     // background: rgb(0, 0, 0, 0.5);
  //     opacity: 1;
  //   }
  // }
}

@include media-breakpoint-up(lg) {
  // .layout-content-renderer-left {
  //   .renderer-left-sidebar-toggle {
  //     // display: block;
  //     .content-toggle-button {
  //       background-color: rgb(179, 255, 0);
  //     }
  //   }
  // }
  .renderer-left-sidebar {
    transform: translateX(0);
    opacity: 1;
    display: block;
    position: relative;
  }
}

@include media-breakpoint-down(lg) {
  // padding: calc(2rem - 0.8rem + 4.45rem + 1.3rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  .renderer-left-sidebar {
    transform: translateX(-100%);
    opacity: 0;

    &.show {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .renderer-left-content {
    // padding: calc(1rem - 0.8rem + 4.45rem + 1.3rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
    padding: calc(1rem - 0.8rem + 1.3rem + #{$navbar-height}) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }

  // .layout-content-renderer-left {
  //   .renderer-left-sidebar-toggle {
  //   // display: block;
  //     .content-toggle-button {
  //       background-color: red;
  //     }
  //   }
  // }
}

</style>
