<template>
  <li class="navigation-header text-truncate">
    <span>{{ t(item.header) }}</span>
  </li>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },

}
</script>

<style lang="scss" scoped>
.navigation-header {
  font-size: 14px;
  margin: 2.286rem 0 .8rem 2.2rem;
  font-weight: bolder;
}
</style>
