<template>
  <div class="app-sidebar">

    <div class="app-sidebar-title">
      我的服務
    </div>

    <vue-perfect-scrollbar
      :settings="{
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }"
      class="navigation-main"
      tagname="ul"
    >
      <div class="navigation-main-content">
        <component
          :is="resolveNavMenuItemComponent(item)"
          v-for="(item, index) in navMenuItems"
          :key="index"
          :item="item"
        />
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { provide, ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { resolveNavMenuItemComponent } from '@/layouts/useVerticalLayout'
// Import any necessary dependencies here
import NavItemHeader from './NavItemHeader.vue'
import NavItemLink from './NavItemsLink.vue'
// import navMenuItems from '@/layouts/navigation/index'

export default {
  components: {
    VuePerfectScrollbar,
    NavItemHeader,
    NavItemLink,
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      // navMenuItems,
      resolveNavMenuItemComponent,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.app-sidebar {
  // margin: 20px 0  20px 10px;
  // position: relative;
  // height: 100%;
  // color: #fff;
  // background-image: linear-gradient(30deg , #1db9ca, #82cfee);
  // border-top-right-radius: 50px;
  // .navigation-main {
  //   min-height: 100%;
  //   list-style: none;
  //   padding: 0;
  // }
  position: relative;
  // background-image: linear-gradient(30deg , #3c6994, #4d8eaa);
  background-color: #455668; // #0b446c;
  // border-radius: 20px;
  color: #fff;
  // min-height: 50vh;
  height: 100%;
  padding: 20px 0;
  // box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  .app-sidebar-title{
    position: absolute;
    font-size: 16px;
    margin: 1rem 1rem .8rem 2.2rem;
    font-weight: bolder;
  }
  .navigation-main {
    margin-top: 50px;
    min-height: 100%;
    max-height: calc(100vh - 300px);
    list-style: none;
    padding: 0;
  }
}
</style>
