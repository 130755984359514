<template>
  <div>
    <app-navbar />
    <!-- <Navbar2 class="navbar-header" /> -->

    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-default' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>

    <!-- <app-footer /> -->
    <Footer />
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import { $themeConfig } from '@themeConfig'
import LayoutContentRendererDefault from './layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from './layout-content-renderer/LayoutContentRendererLeft.vue'
// import Navbar2 from '@/components/Navbar/Navbars2.vue'
import AppNavbar from './components/app.navbar/app-navbar.vue'
// import AppFooter from './components/app-footer.vue'
import Footer from '@/components/Footer/Footer.vue'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    // Navbar2,
    AppNavbar,
    // AppFooter,
    Footer,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
  },
  data() {
    return {
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      return 'layout-content-renderer-default'
    },
  },
  methods: {
    logout() {
      this.$swal({
        title: '登出',
        text: '你是否要登出此帳號',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('api/logout')
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          this.$store.commit('app/UPDATE_USERDATA_STATE', null)
          this.$router.push({ name: 'page-login' })
        }
      })
    },
  },
  setup() {
    const {
      routerTransition, // navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    return {
      routerTransition,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
.app-container {
  min-height: 100vh;
  background-color: rgb(205, 181, 115);
}
.navbar-header {
  position: fixed;
  width: 100%;
  z-index: 500;
}
</style>
