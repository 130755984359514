<template>
  <div class="footer">
    <footer>
      <div class="text-center">
        <div class="contact-image d-block d-md-none">
          <div class="image-logo">
            <router-link :to="{ name: 'home' }">
              <img
                :src="appLogoImage"
                alt="logo"
                class="logo"
              >
            </router-link>
          </div>
          <!-- <div class="image-line">
            <h3>官方LINE@</h3>
            <img
              class="Line"
              src="/images/LineQR.jpg"
              alt="Line"
            >
          </div> -->
        </div>
      </div>
      <!-- <div class="d-block d-md-none">
        <div class="contact-title">
          保護你的遊戲世界
          <span class="d-none d-sm-inline-block contact-mark">，</span>
          <br class="d-inline-block d-sm-none">
          由硬主機來守護！
        </div>
      </div> -->

      <div class="contact-contain d-none d-md-block">
        <div class="row">
          <div class="col-lg-3">
            <div class="contact-image">
              <div class="image-logo">
                <router-link :to="{ name: 'home' }">
                  <img
                    :src="appLogoImage"
                    alt="logo"
                    class="logo"
                  >
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-lg-7">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-3 item">
                  <!-- d-none d-sm-block -->
                  <router-link
                    :to="{ name: 'home' }"
                    class="link"
                  >
                    <h3>關於我們</h3>
                  </router-link>
                  <ul>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        技術優勢
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        安全和防護
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3 item d-none d-sm-block">
                  <router-link
                    :to="{ name: 'home' }"
                    class="link"
                  >
                    <h3>VPS主機</h3>
                  </router-link>
                  <ul>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        什麼是VPS主機
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        VPS主機特色與效益
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        VPS主機方案
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3 item d-none d-sm-block">
                  <router-link
                    :to="{ name: 'home' }"
                    class="link"
                  >
                    <h3>實體主機</h3>
                  </router-link>
                  <ul>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        台灣抗攻擊實體主機
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        無限防禦實體主機
                      </router-link>
                    </li>

                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        無限盾防禦實體主機方案
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div class="col-md-3 item d-none d-sm-block">
                  <router-link
                    :to="{ name: 'home' }"
                    class="link"
                  >
                    <h3>最新消息</h3>
                  </router-link>
                  <ul>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        重要消息
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        商品資訊
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'home' }"
                        class="link"
                      >
                        常見問題
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-2">
            <div class="contact-image">
              <div class="image-line">
                <h3 class="text-nowrap">
                  官方LINE@
                </h3>
                <a
                  href="https://line.me/ti/p/z5SEET2GfM"
                  target="_blank"
                >
                  <img
                    class="Line"
                    src="/images/LineQR.jpg"
                    alt="Line"
                  >
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="copyright">
        <a
          href="/home.html"
          class="text-center feature-item-link"
        >
          曠世國際
          <!-- －最好的台灣抗DDOS攻擊遊戲主機 -->
        </a>
        <span>Copyright© 2016-{{ new Date().getFullYear() }}. All Rights Reserved.</span>
      </div>
    </footer>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
$background-color: #0b446c;

.footer {
  // position: relative;
  bottom: 0;
  background-color: $background-color;
  padding-top: 30px;
  z-index: 1200;

  .contact-image {
    margin-left: 30px;;
    margin-bottom: 30px;
    .image-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .image-line {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        color: #00B900;
        text-align: center;
        // font-size: 2.2rem;
        font-weight: 700;
      }
      img {
        border: 5px solid #01b901;
        width: 80px;
      }
    }
  }

  .contact-title {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    // padding: 40px 0;
    font-size: 30px;
    font-weight: 600;
    color: #FFFC7D;
    padding-bottom: 30px;
  }

  .contact-contain {
    margin-bottom: 20px;
    h3 {
      margin-top: 0;
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 16px;
      // color:#ffffff;
    }

    .link {
      cursor: pointer;
      h3 {
        color: #26ccde;
      }
      h3:hover {
        color: #2196f3;
      }
    }

    ul {
      color:#ffffff;
      padding:0;
      list-style:none;
      line-height:1.6;
      font-size:14px;
      margin-bottom:0;
      a {
        color: inherit;
        text-decoration: none;
        opacity: 0.8;
        &:hover {
          opacity:1;
        }
      }
    }
  }

  .copyright {
    background-color: #000000;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    padding: 15px;
    color: #b7b7b7;
    .feature-item-link {
      color: #26ccde;
      margin-right: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    .contact-image {
      .image-line {
        display: none;
      }
    }
    .copyright {
      flex-direction: column;
    }
  }
}

@media (max-width:768px) {
  .footer {
    .contact-image {
      margin-left: 0;
      // .image-line {
      //   display: none;
      // }
    }
    .contact-title {
      font-size: 20px;
    }
    .container {
      .item {
        text-align: center;
        padding-bottom:20px;
      }
    }
  }
}

</style>
