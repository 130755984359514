import { computed } from '@vue/composition-api' // , watch
import store from '@/store'

export default function usAppConfig() {
  const routerTransition = computed({
    get: () => store.state.appConfig.layout.routerTransition,
    set: val => {
      store.commit('appConfig/UPDATE_ROUTER_TRANSITION', val)
    },
  })

  return {
    routerTransition,
  }
}
