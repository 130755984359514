<template>
  <div class="layout-content-renderer-default">
    <!-- <Navbar2 class="navbar-header">
      <template v-slot:nav-bar-language>
        <div v-if="!$store.state.app.userData">
          <span class="mr-1">
            <b-link :to="{ name: 'page-login' }">登入</b-link>
          </span>

          <span>
            語系
          </span>
        </div>

        <div v-else>
          <span class="mr-1">
            <b-link :to="{ name: 'user-profile' }">個人資料</b-link>
          </span>

          <span class="mr-1">
            <b-link @click="logout">登出</b-link>
          </span>
        </div>
      </template>

    </Navbar2> -->

    <div class="default-content">
      <router-view />
      <!-- <app-footer /> -->
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
// import Navbar2 from '@/components/Navbar/Navbars2.vue'
// import AppFooter from '../components/app-footer.vue'

export default {
  components: {
    // Navbar2,
    // AppFooter,
  },
  setup() {
    const { routerTransition } = useAppConfig()

    return {
      routerTransition,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.layout-content-renderer-default {
  .navbar-header {
    position: fixed;
    width: 100%;
  }
  .default-content {
    min-height: 100vh;
  }
}
</style>
