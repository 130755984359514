export default {
  navbarData: {
    navbar: [
      {
        header: '關於我們',
        key: 'about',
        route: null,
        link: null,
        type: 'dropdown-text',
        children: [
          {
            title: '關於曠世國際',
            route: { name: 'about' },
            link: null,
          },
          {
            title: '最新消息',
            route: { name: 'news' },
            link: null,
          },
        ],
      },
      {
        header: 'VPS主機',
        key: 'vps',
        route: null,
        link: null,
        type: null,
        children: [],
      },
      {
        header: '實體主機',
        key: 'host',
        route: null,
        link: null,
        type: 'dropdown-text',
        children: [
          {
            title: '台灣抗攻擊實體主機',
            route: null,
            link: null,
          },
          {
            title: '無限防禦實體主機',
            route: null,
            link: null,
          },
          {
            title: '無限盾防禦實體主機方案',
            route: null,
            link: null,
          },
        ],
      },
      {
        header: '網域註冊',
        key: 'domain',
        route: { name: 'domain' },
        link: null,
        type: null,
        children: [],
      },
      {
        header: '聯絡我們',
        key: 'contact',
        link: null,
        type: null,
      },
      // {
      //   header: '我的服務',
      //   key: 'service',
      //   link: null,
      //   type: 'dropdown-container',
      //   children: [
      //     {
      //       title: '關於曠世國際',
      //       route: { name: 'about' },
      //       link: null,
      //     },
      //   ],
      // },
    ],
    gallery: {
      title: '形象廣告圖片',
      data: [
        {
          title: '星星',
          image: 'https://i.loli.net/2019/11/23/cnKl1Ykd5rZCVwm.jpg',
          link: null,
          alt: 'Star',
        },
        {
          title: '太陽',
          image: 'https://i.loli.net/2019/11/16/FLnzi5Kq4tkRZSm.jpg',
          link: null,
          alt: 'Sun',
        },
        {
          title: '樹木',
          image: 'https://i.loli.net/2019/10/18/uXF1Kx7lzELB6wf.jpg',
          link: null,
          alt: 'Tree',
        },
        {
          title: '天空',
          image: 'https://i.loli.net/2019/10/18/buDT4YS6zUMfHst.jpg',
          link: null,
          alt: 'Sky',
        },
      ],
    },
    config: {
      useGallery: false,
    },
  },
}
