<template>
  <div :class="navbarClass">
    <b-navbar
      :toggleable="false"
      class="header-navbar align-items-center navbar-brand-center navbar-fixed fixed-top"
      :class="{
        'navbar-shadow': scrolledTo >= 100,
      }"
    >
      <div class="navbar-container d-flex content align-items-center mx-xl-5 main-menu-content">

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1">

          <b-link
            class="navbar-brand"
            :style="{margin: $store.getters['app/currentBreakPoint'] === 'xl' || $store.getters['app/currentBreakPoint'] === 'lg' ? '15px' : 0}"
            to="/"
          >
            <b-img
              :src="`/${imagePrefix}/images/logo-dark.png`"
              alt="logo"
            />
          </b-link>
        </div>

        <!-- Right Col -->
        <div class="nav-router d-lg-block d-none">
          <ul class="nav-links">
            <!-- nav-link-dropdown-text nav-link-dropdown-container nav-link-->
            <li
              v-for="item in navbarData.navbar"
              :key="`nav-link-${item.key}`"
              :class="item.type ? `nav-link-${item.type}` : 'nav-link-text'"
              @mouseenter="dropdownMenu(`dropdown-${item.key}`, true)"
              @mouseleave="dropdownMenu(`dropdown-${item.key}`, false)"
            >
              <div
                class="d-flex align-item-center"
                @click="submitClickHeader(item)"
              >
                <span class="nav-link-title">{{ item.header }}</span>
              </div>

              <ul
                v-if="item.type === 'dropdown-text'"
                :ref="`dropdown-${item.key}`"
                class="dropdown"
              >
                <li
                  v-for="(dropdownItem, index) in item.children"
                  :key="`dropdown-item-${index + 1}`"
                  class="dropdown-item"
                  @click="submitClickTitle(dropdownItem)"
                >
                  <div class="d-flex align-item-center dropdown-item-text">
                    <span class="dropdown-item-text-name">{{ dropdownItem.title }}</span>
                  </div>
                </li>
              </ul>

              <ul
                v-if="item.type === 'dropdown-container' && item.key === 'service'"
                :ref="`dropdown-${item.key}`"
                class="dropdown"
              >
                <li>
                  <!-- {{ item }} -->
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="ml-1 mr-3 mr-lg-0">
          <b-navbar-nav class="nav align-items-center ml-auto">
            <div
              v-if="!userDataState"
              class="user-avater"
            >
              <div
                class="cursor-pointer"
                @click="() => $store.dispatch('app/linkUserBackend', { route: 'login', type: 'blank'})"
              >
                <b-avatar
                  size="36"
                  variant="primary"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
              </div>
            </div>

            <b-nav-item-dropdown
              v-if="userDataState"
              right
              toggle-class="d-flex align-items-center dropdown-user-link"
              class="dropdown-user"
            >
              <template #button-content>
                <div class="user-avater">
                  <b-avatar
                    size="36"
                    variant="primary"
                    :src=" userDataState ? `${userDataState.image}` : null"
                    class="badge-minimal"
                  >
                    <feather-icon
                      v-if="!userDataState"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </div>
              </template>

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="() => $store.dispatch('app/linkUserBackend', { route: 'profile'})"
              >
                <feather-icon
                  size="16"
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>個人資料</span>
              </b-dropdown-item>

              <!-- 欄: 目前餘額  -->
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="() => $store.dispatch('app/linkUserBackend', { route: 'quota'})"
              >
                <feather-icon
                  size="16"
                  icon="CreditCardIcon"
                  class="mr-50"
                />
                <span>餘額： $ {{ parseInt(userDataState.point || 0, 10).toLocaleString() }}</span>
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
              >
                <feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                <span>登出</span>
              </b-dropdown-item>

              <!-- {{ userDataState }} -->
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>

        <!-- Nav Menu Toggler -->
        <div class="nav navbar-nav d-lg-none">
          <li class="nav-item hamburger">
            <input
              id="burger-toggle"
              v-model="isVerticalMenuActive"
              type="checkbox"
            >

            <label
              for="burger-toggle"
              class="burger-menu"
            >
              <div class="line" />
              <div class="line" />
              <div class="line" />
            </label>
          </li>
        </div>
      </div>

      <!-- (手機版) -->
      <div
        class="navbar-menu d-lg-none"
        :class="{ 'navbar-menu-active': isVerticalMenuActive }"
      >
        <div class="menu-inner">
          <ul class="menu-nav">

            <li
              v-for="item in navbarData.navbar"
              :key="`nav-link-${item.key}`"
              class="menu-nav-item"
            >
              <div
                class="menu-nav-title"
                :class="{ 'menu-nav-link': !item.type || item.type === 'text' }"
                @click="submitClickHeader(item)"
              >
                <span class="nav-link-text">{{ item.header }}</span>
              </div>

              <ul
                v-if="item.type === 'dropdown-text'"
                :ref="`dropdown-${item.key}`"
                class="dropdown"
              >
                <li
                  v-for="(dropdownItem, index) in item.children"
                  :key="`dropdown-item-${index + 1}`"
                  class="dropdown-item"
                  @click="submitClickTitle(dropdownItem)"
                >
                  <div class="d-flex align-item-center dropdown-item-text">
                    <span class="dropdown-item-text-name">{{ dropdownItem.title }}</span>
                  </div>
                </li>
              </ul>

            </li>

          </ul>

        </div>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import {
  BLink, BImg, BNavbar, BNavbarNav, BNavItemDropdown, BDropdownDivider, BAvatar, BDropdownItem,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import { useScrollListener } from '@core/comp-function/misc/event-listeners'
import configData from './configData'
import useVerticalLayout from './useVerticalLayout'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BImg,
    BNavbar,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      navbarData: configData.navbarData,
      navbarClass: null,
    }
  },
  computed: {
    userDataState() {
      return this.$store.state.app.userData
    },
  },
  watch: {
    $route() {
      this.updateNavbarStyle()
    },

    scrolledTo() {
      this.updateNavbarStyle()
    },
  },
  mounted() {
    this.updateNavbarStyle()
  },
  methods: {
    updateNavbarStyle() {
      const router = this.$router.currentRoute.name
      // console.log(router)
      switch (router) {
        case 'domain':
          this.navbarClass = 'light-layout'
          break
        case 'home':
          if (this.scrolledTo < 100) this.navbarClass = 'light-layout'
          else this.navbarClass = 'dark-layout'
          break
        case 'error-404':
          this.navbarClass = 'light-layout'
          break
        default:
          // if (this.scrolledTo < 100) this.navbarClass = null
          this.navbarClass = 'dark-layout'
          break
      }
    },

    submitClickHeader(item) {
      if (item.route) {
        if (item.route.name === this.$router.currentRoute.name) return
        this.$router.push(item.route)
      }
    },

    submitClickTitle(item) {
      if (item.route) {
        if (item.route.name === this.$router.currentRoute.name) return
        this.$router.push(item.route)
      }
    },

    // (顯示)更多
    dropdownMenu(name, open) {
      let dropdown = null
      if (!this.$refs[name]) return
      if (this.$refs[name].length) {
        const [data] = this.$refs[name]
        dropdown = data
      } else dropdown = this.$refs[name]

      if (!dropdown) return
      if (open) dropdown.classList.add('show')
      else dropdown.classList.remove('show')
    },

    logout() {
      this.$swal({
        title: '登出',
        text: '你是否要登出此帳號',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('api/logout')
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          this.$store.commit('app/UPDATE_USERDATA_STATE', null)
        }
      })
    },
  },
  setup() {
    const { imagePrefix } = $themeConfig.app

    const {
      navbarType,
    } = useAppConfig()

    const {
      isVerticalMenuActive, resizeHandler,
    } = useVerticalLayout(navbarType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const { scrolledTo } = useScrollListener()

    return {
      imagePrefix,

      navbarType,
      scrolledTo,
      isVerticalMenuActive,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

$dropdown-background-color: #f8f9fa;
$text-color: #0a0a0a;
$text-hover-color: rgba($primary-color, 0.5); //#60f1f2

@mixin sp-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}

@mixin pc-layout {
  @media screen and (min-width: 751px) {
    @content;
  }
}

// (導航)
.header-navbar {
  transition: all 0.5s ease;
  background: transparent;
  left: 0 !important;
  border-bottom: 2px solid #ffffffa8;
}

// (導航)
.navbar-shadow {
  background: white;
  border-bottom: 5px solid #57dbf8b7;
  box-shadow: #98e8fc69 0px 4px 20px 0px;
  .navbar-brand {
    margin: 0 15px !important;
  }
}

// (導航開關)
.navbar-nav {
  .hamburger {
    #burger-toggle {
      position: absolute;
      appearance: none;
      opacity: 0;

      &:checked {
        & ~ .burger-menu {
          .line {
            &::after {
              transform: translateX(0);
            }

            &:nth-child(1) {
              transform: translateY(calc(var(--burger-menu-radius) / 5))
                rotate(45deg);
            }

            &:nth-child(2) {
              transform: scaleX(0);
            }

            &:nth-child(3) {
              transform: translateY(calc(var(--burger-menu-radius) / -5))
                rotate(-45deg);
            }
          }
        }
      }
    }
    .burger-menu {
      --burger-menu-radius: 4em;
      transition: 0.5s ease;

      position: absolute;
      top: 5px;
      right: 0;
      z-index: 100;
      display: block;
      width: var(--burger-menu-radius);
      height: var(--burger-menu-radius);
      outline: none;
      cursor: pointer;

      .line {
        position: absolute;
        width: 30px;
        height: 4px;
        background: $primary-darker-color;
        border-radius: 10px;
        overflow: hidden;
        transition: 0.5s;

        &:nth-child(1) {
          top: 30%;
        }

        &:nth-child(2) {
          top: 50%;
        }

        &:nth-child(3) {
          top: 70%;
        }

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $primary-color;
          transform: translateX(-100%);
          transition: 0.25s;
        }

        @for $i from 2 through 3 {
          &:nth-child(#{$i})::after {
            transition-delay: 0.1s * ($i - 1);
          }
        }
      }

      &:hover {
        .line::after {
          transform: translateX(0);
        }
      }
    }
  }
}

// (手機導航)
.navbar-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  opacity: 0;
  overflow-x: hidden;
  visibility: hidden;
  transition: 0.3s;
  overscroll-behavior: contain;

  .menu-inner {
    margin-top: 80px;
    width: 100%;
  }

  @include sp-layout {
    display: block;
  }

  .menu-nav {
    // display: flex;
    // flex-wrap: wrap;
    // margin: 0;
    // padding: 0;
    // text-align: center;
    list-style-type: none;
    margin-bottom: 20px;
    // background-color: red;

    @include sp-layout {
      flex-direction: column;
    }

    // &-item {
    //   flex: 1;
    // }
    &-title {
      position: relative;
      display: inline-flex;
      font-size: 1.5rem;
      color: white;
      text-decoration: none;
      margin-bottom: 10px;
      white-space: nowrap;
      margin-right: 10px;
    }

    &-link {
      cursor: pointer;
      &:hover {
        color: $primary-color;
        font-weight: bolder;
        text-decoration: underline;
      }
    }
  }

  .menu-nav-item {
    margin-bottom: 10px;
    .dropdown{
      position: relative;
      // background-color: rgba(11, 223, 212, 0.75);
      border-radius: 5px 0 0 5px;
      transition: 0.2s 0s;
      padding: 0;
      margin-bottom: 30px;
      .dropdown-item {
        padding: 5px 10px;
        &-text {
          width: 100%;
          &-name {
            font-size: 15px;
            color: white;
          }
        }
      }
      li {
        list-style: none;
        margin: 0;
        color: $text-color;
        font-size: 18px;
        &:hover {
          background-color: $text-hover-color;
          border-radius: 5px 0 0 5px;
          cursor: pointer;
        }
      }
    }
  }

  // .menu-gallery {
  //   margin-top: 60px;
  //   text-align: center;
  //   .gallery-title {
  //     font-size: 24px;
  //     color: white;
  //     overflow: hidden;

  //     p {
  //       font-size: 12px;
  //       letter-spacing: 2px;
  //       text-transform: uppercase;
  //       transform: translateY(102%);
  //     }
  //   }
  // }

  // .images {
  //   margin-top: 36px;
  //   display: flex;
  //   flex-wrap: wrap;

  //   @include sp-layout {
  //     justify-content: center;
  //   }

  //   .image-link {
  //     width: 15vw;
  //     margin: 0 12px;
  //     overflow: hidden;

  //     @include sp-layout {
  //       width: 40vw;
  //       margin: 0 12px 12px 0;
  //     }

  //     .image {
  //       position: relative;
  //       transition: 0.6s;

  //       &::before {
  //         position: absolute;
  //         content: attr(data-label);
  //         top: 0;
  //         left: 0;
  //         z-index: 1;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         width: 100%;
  //         height: 100%;
  //         color: white;
  //         background: rgba(0, 0, 0, 0.6);
  //         opacity: 0;
  //         transition: 0.4s;
  //       }
  //     }

  //     &:hover .image {
  //       transform: scale(1.2);

  //       &::before {
  //         opacity: 1;
  //       }
  //     }
  //   }

  //   img {
  //     height: 250px;
  //     transform: translateY(102%);
  //   }
  // }
}

.navbar-menu-active {
  opacity: 1;
  visibility: visible;

  .menu-nav-link span div, img, .gallery-title p {
    transform: translateY(0);
    transition: 1.2s 0.1s cubic-bezier(0.35, 0, 0.07, 1);
  }

  .image-link {
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) img {
        transition-delay: 0.1s + 0.08s * $i;
      }
    }
  }
}

.nav-router {
  .nav-links {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    .nav-link-text, .nav-link-dropdown-container, .nav-link-dropdown-text {
      // position: relative;
      padding: 1rem;
      font-weight: 600;
      text-align: center;
      cursor: default;
      .nav-link-title {
        position: relative;
        color: $text-color;
        font-size: 18px;
        &:hover {
          color: $primary-color;
          text-shadow: 0 0 20px #86ffff;
          cursor: pointer;
        }
      }
    }

    .nav-link-dropdown-container {
      .dropdown{
        visibility: hidden;
        opacity: 0;
        right: 20px;
        position: fixed;
        text-align: left;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
        margin-left: 0px;
        background: $dropdown-background-color;
        width: 60vw;
        border-radius: 5px;
        transition: 0.2s 0s;
        padding: 20px;
        .dropdown-item {
          padding: 3px 10px;
          &-text {
            display: flex;
            align-items: center;
            &-name {
              font-size: 15px;
              color: $text-color;
            }
          }
        }
        li {
          list-style: none;
          margin: 0;
          min-width: 250px;
          color: $text-color;
          font-size: 18px;
          &:hover {
            background-color: $text-hover-color;
            cursor: pointer;
          }
        }
        // &:before {
        //   bottom: 100%;
        //   left: 10px;
        //   border: solid transparent;
        //   content: " ";
        //   height: 0;
        //   width: 0;
        //   position: absolute;
        //   pointer-events: none;
        //   border-color: rgba(136, 183, 213, 0);
        //   border-bottom-color: #fff;
        //   border-width: 10px;
        //   margin-left: 10px;
        // }
        &:hover {
          visibility: visible;
          opacity: 1;
          margin-top: 20px;
        }
        &.show {
          visibility: visible;
          opacity: 1;
          margin-top: 20px;
        }
      }
    }

    .nav-link-dropdown-text {
      position: relative;
      .dropdown{
        visibility: hidden;
        opacity: 0;
        top: 60%;
        position: absolute;
        text-align: left;
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
        margin-left: 0px;
        background: $dropdown-background-color;
        border-radius: 5px;
        transition: 0.2s 0s;
        padding: 10px 0 ;
        .dropdown-item {
          padding: 3px 10px;
          &-text {
            display: flex;
            align-items: center;
            &-name {
              font-size: 15px;
              color: $text-color;
            }
          }
        }
        li {
          list-style: none;
          margin: 0;
          min-width: 250px;
          &:hover {
            background-color: $text-hover-color;
            cursor: pointer;
          }
        }
        &:before {
          bottom: 100%;
          left: 10px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: $dropdown-background-color;
          border-width: 10px;
          margin-left: 10px;
        }
        &:hover {
          visibility: visible;
          opacity: 1;
          margin-top: 20px;
        }
        &.show {
          visibility: visible;
          opacity: 1;
          margin-top: 20px;
        }
      }
    }
  }
}

.dark-layout {
  .header-navbar {
    background: #0b446c;
    border: none;
  }

  .navbar-shadow {
    background: #0b446c;
    border: none;
    box-shadow: none;
  }

  // (導航)
  .navbar-brand {
    img {
      filter: invert(1) !important;
    }
  }

  .nav-router {
    .nav-links {
      .nav-link-text, .nav-link-dropdown-container, .nav-link-dropdown-text {
        .nav-link-title {
          color: white;
        }
      }
    }
  }
}

.light-layout {
  .header-navbar {
    // background: #0b446c;
    border: none;
  }

  .navbar-shadow {
    background: #0b446c;
    border: none;
    box-shadow: none;
  }

  // (導航)
  .navbar-brand {
    img {
      filter: invert(1) !important;
    }
  }

  .nav-router {
    .nav-links {
      .nav-link-text, .nav-link-dropdown-container, .nav-link-dropdown-text {
        .nav-link-title {
          color: white;
        }
      }
    }
  }
}

</style>
