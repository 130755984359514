<template>
  <li
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon
        v-if="item.type === 0"
        :icon="item.icon || 'CircleIcon'"
        class="menu-icon"
      />
      <b-img
        v-if="item.type === 1"
        rounded
        height="18"
        width="18"
        class="mr-1 menu-icon"
        :src="item.imgIcon"
      />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <!-- // {{ isActive }} -->
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BLink, BBadge, BImg } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { isNavLinkActive, navLinkProps } from '@/layouts/useVerticalLayout'

export default {
  components: {
    BImg,
    BLink,
    BBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateIsActive()
      },
    },
  },
  setup(props) {
    const isActive = ref(false)

    const linkProps = navLinkProps(props.item)

    const updateIsActive = () => {
      isActive.value = isNavLinkActive(props.item)
    }

    const { t } = useI18nUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,
      t,
    }
  },

}
</script>

<style lang="scss" scoped>
.nav-item {
  margin: 0 0 0 15px;
  padding: 10px 15px 10px 15px;
  .menu-title {
    color: #ffffff;
    font-weight: bolder;
    font-size: 16px;
  }
  &:hover {
    .menu-title {
      color: #76c9ef;
    }
    outline: none;
    position: relative;
    background-color: #354853;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    // border-radius: 10px;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   background-color: transparent;
    //   bottom: 100%;
    //   right: 0;
    //   height: 35px;
    //   width: 35px;
    //   border-bottom-right-radius: 18px;
    //   box-shadow: 0 20px 0 0 #f8f8f8;
    // }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   background-color: transparent;
    //   top: 38px;
    //   right: 0;
    //   height: 35px;
    //   width: 35px;
    //   border-top-right-radius: 18px;
    //   box-shadow: 0 -20px 0 0 #f8f8f8;
    // }
    .menu-icon {
      transition: transform 0.5s ease-in-out;
      animation: rotate 0.8s linear infinite;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }
}

.active {
  background-color: #202a30;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  // border-radius: 10px;
  .menu-title {
    color: #6ce7ee;
  }
  &:hover {
    &::after {
      box-shadow: 0 20px 0 0 #c9ebff;
    }

    &::before {
      box-shadow: 0 -20px 0 0 #c9ebff;
    }
  }
}
</style>
